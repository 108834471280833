










import { Component, Vue } from 'vue-property-decorator'
import footer_ from '@/components/footer/footer.vue'
import header_ from '@/components/header/header.vue'
import { MetaInfo } from 'vue-meta'

@Component({
  components: { footer_, header_ },
  metaInfo(): MetaInfo {
    return {
      title: '',
      titleTemplate: '%s | Florian Stradner | Wien',
    }
  },
})
export default class App extends Vue {}
