















import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    navbar: () => import('@/components/header/navbar.vue'),
  },
})
export default class Header extends Vue {}
