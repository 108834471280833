import VueRouter from 'vue-router'

interface NavItem {
  name: string
  path: string
}

export function getNavItems(router: VueRouter, header = true): NavItem[] {
  if (!router.options.routes) return []
  const headerOrFooter: string = header ? 'isHeader' : 'isFooter'
  return router.options.routes
    .filter((route) => route.meta?.[headerOrFooter])
    .map((route) => {
      if (route.name) {
        return { name: route.meta.title || route.name, path: route.path }
      }
      return { name: fallBackRouteName(route.path), path: route.path }
    })
}

function fallBackRouteName(path: string): string {
  const splittedArray = path.split('/')
  console.log('splittedArray:', splittedArray)
  const length = splittedArray.length
  console.log('length:', length)
  if (splittedArray[length - 1] === '' && length > 1) {
    return cap(splittedArray[length - 2])
  }
  return cap(splittedArray[length - 1])
}

function cap(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
}
