












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class CardItem extends Vue {
  @Prop({ required: true })
  private readonly item!: Card
  @Prop({ required: true })
  private readonly index!: number

  isOpen = false

  clickHandler(): void {
    this.isOpen = !this.isOpen
  }
}
