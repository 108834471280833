



















































































import { Component, Vue } from 'vue-property-decorator'
import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src
import Cards from '@/components/Cards.vue' // @ is an alias to /src
import CardItem from '@/components/CardItem.vue' // @ is an alias to /src
import { MetaInfo } from 'vue-meta'

@Component({
  components: {
    HelloWorld,
    Cards,
    CardItem,
  },
  metaInfo(): MetaInfo {
    return {
      title: 'Veränderung erleben durch Coaching',
      meta: [
        {
          name: 'description',
          content:
            'Ich biete Unterstützung für junge und angehende Führungskräfte, arbeite online und in meiner Praxis und bringe Erfahrungen aus dem Leistungssport mit ein in die Beratung.',
        },
      ],
    }
  },
})
export default class Home extends Vue {
  cards: Card[] = [
    {
      title: 'Stress',
      text:
        'Ich hatte immer riesigen Stress und habe letztendlich feststellen müssen, dass meine Zeit und Energie endlich ist. Ich kümmere mich jetzt viel stärker um die Frage, wohin meine Energien gehen und was ich in meinem Leben erreichen will!',
    },
    {
      title: 'Selbstmanagement',
      text:
      'Jetzt weiß ich, dass die Ordnung (Selbstmanagement) bei mir anfängt und dann von mir auf meine Mitarbeiter ausstrahlt. Was ich fordere, muss ich auch selber leben.',
    },
    {
      title: 'Schicksale',
      text:
      'Das Leben ist kein Ponyhof: Es gibt Rückschläge, Niederlagen und Schicksale. Aber ich kann jetzt besser damit umgehen. Und ich habe festgestellt, dass es immer weitergeht.',
    },
    {
      title: 'Angst',
      text:
        'Ich hatte immer Angst vor dem Bösen in mir, habe die Gedanken und Phantasien gespürt. Aber jetzt kann ich sie gut beherrschen und weiß, dass ich trotzdem "gut" sein kann, auch ein guter Vater bin, etc.',
    },
    {
      title: 'Selbstzweifel',
      text:
        'Ich habe immer so viele "Mauern" um mich herum gebaut, mich nicht getraut dahinter zu schauen. Jetzt teste ich, ob diese Mauern real sind. Die meisten Mauern habe ich mittlerweile niedergerissen, sie hinter mir gelassen. Ich fühle mich jetzt viel freier, werde anders wahrgenommen, es geht mir gut damit.',
    },
    {
      title: 'Potenzial',
      text:
      'Plötzlich entdecke ich überall in meinem Leben schöne Dinge, positive Überraschungen, Freude. Sie waren immer da, aber ich habe sie einfach nicht gesehen, nicht hingeschaut.',
    },
    {
      title: 'Vorbild',
      text:
        'Ich habe bemerkt, dass mein Verhalten als Führungskraft immer wirkt: zB auch wenn ich keine Entscheidung treffe, dann ist das eine Entscheidung, die meine Mitarbeiter betrifft und mich passiv wirken lässt.',
    },
    {
      title: 'Neid',
      text:
        'Ich habe mich immer mit anderen verglichen: Meinen Kolleginnen, Chefs, Freundinnen, bei Instagram. Mittlerweile schaue ich nur mehr wie ich früher war, vergleiche was ich gestern getan habe mit dem was ich heute tue. Und es geht mir soviel besser damit!',
    },
    {
      title: 'Zorn',
      text:
        'Alles musste sofort raus und oft waren da auch nicht so nette Aussagen dabei. Ich war richtig aggressiv zu meinen Mitarbeitern. Dann habe ich versucht die Gefühle zu unterdrücken, bin aber trotzdem regelmäßig explodiert wie ein Kochtopf. Mittlerweile muss ich diese Gefühle nicht mehr unterdrücken, sondern bin ausgelichener, habe sogar wieder Freude an meinen täglichen Aufgaben.',
    },
  ]
}
