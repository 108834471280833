import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/ueber-mich',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      isHeader: true,
      title: 'Über mich',
    },
  },
  {
    path: '/ueber-meine-arbeit',
    name: 'Work',
    component: () => import(/* webpackChunkName: "work" */ '../views/Work.vue'),
    meta: {
      isHeader: true,
      title: 'Über meine Arbeit',
    },
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
    meta: {
      isHeader: true,
      title: 'Kontakt',
    },
  },
  {
    path: '/media',
    name: 'Media',
    component: () =>
      import(/* webpackChunkName: "media" */ '../views/Media.vue'),
    meta: {
      isHeader: true,
    },
  },
  // {
  //   path: "/blog",
  //   name: "Blog",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/Blog.vue"),
  //   meta: {
  //       isHeader: true,
  //       title: 'Blog'
  //     },
  // },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () =>
      import(/* webpackChunkName: "impressum" */ '../views/Impressum.vue'),
    meta: {
      isFooter: true,
      title: 'Impressum',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    console.log('to:', to)
    if (savedPosition) {
      return savedPosition
    } else if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth',
        // , offset: { x: 0, y: 10 }
      }
    }
    return { x: 0, y: 0 }
  },
})

export default router
