



























































import { Component, Vue } from 'vue-property-decorator'
import { getNavItems } from '@/helper/helper'

interface FooterItem {
  title: string
  icon: string
  text: string
  text2?: string
  text3?: string
  link?: string
}
interface NavItem {
  name: string
  path: string
}
@Component
export default class Footer extends Vue {
  year = new Date().getFullYear()
  footerList: FooterItem[] = [
    {
      title: 'Telefon',
      icon: 'T',
      text: '+43 699 116 09 114',
      link: 'tel',
    },
    {
      title: 'Mail',
      icon: 'M',
      text: 'info@stradner.coach',
      link: 'mailto',
    },
    // {
    //   title: 'Rechtliches:',
    //   icon: '§',
    //   text: 'Impressum',
    //   link: 'link',
    // },
    {
      title: '',
      icon: 'C',
      text: `© ${this.year} by Florian Stradner`,
      text2: 'Am Schwedenplatz, 1010 Wien',
    },
  ]

  addLinkToFooterText({ link, text }: FooterItem): string {
    if (link === 'link')
      return `<a href="/${text.toLowerCase()}" class="text-white">${text}</a>`
    else if (link) {
      return `<a href="${link}:${text}">${text}</a>`
    }
    return text
  }

  private get navItems(): NavItem[] {
    return getNavItems(this.$router, false)
  }
}
